import React, { useState } from 'react';
import TextInput from './TextInput';
import TextInputV2 from './TextInputV2';



const Step1 = () =>
{

    const [ value, setValue ] = useState()


    return (
        // <div className="main-div">
        //     <div className="left">
        //         <div className='sec active'>Personal details</div>
        //         <div className='sec'>Your Address</div>
        //         <div className='sec'>Educational Details</div>
        //         <div className='sec'>Work Experience Details</div>
        //         <div className='sec'>Other Details</div>
        //         <div className='sec'>Resume / CV</div>
        //     </div>
        <div className="right">
            <form className='main-scroll'>
                <TextInput type="text" field="input1" placeholder="Enter your name " label="Name: *" />

                <TextInput type="email" field="input2" placeholder="Enter your email address" label="Email: *" />

                {/* <TextInput type="tel" field="input3" placeholder="+91-8597406694" label="Contact Phone Number: *" />

                <TextInput type="tel" field="input4" placeholder="+91-8597406694" label="Whatsapp Number: *" /> */}


                <TextInputV2 type="tel" field="input3" placeholder="+91 85974 06694" label="Contact Phone Number: *" />

                <TextInputV2 type="tel" field="input4" placeholder="+91 85974 06694" label="Whatsapp Number: *" />

            </form>
        </div>
        // </div>
    );
};

export default Step1;
