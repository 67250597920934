import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import Step5 from './components/Step5';
import CV from './CV';

const InternForm = () =>
{
    const navigate = useNavigate();

    const [ currentComponent, setCurrentComponent ] = useState( 1 );

    const nextClicked = () =>
    {
        setCurrentComponent( ( prevComponent ) => ( prevComponent % 6 ) + 1 );
    };

    const prevClicked = () =>
    {
        setCurrentComponent( ( prevComponent ) => ( prevComponent - 2 + 6 ) % 6 + 1 );
    };

    const pageChangeClick = ( index ) =>
    {
        setCurrentComponent( index );
    };


    const renderCurrentComponent = () =>
    {
        switch ( currentComponent )
        {
            case 1:
                return <Step1 />;
            case 2:
                return <Step2 />;
            case 3:
                return <Step3 />;
            case 4:
                return <Step4 />;
            case 5:
                return <Step5 />;
            case 6:
                return <CV />;
            default:
                return null;
        }
    };



    return (
        <section>
            <div className="container" style={ { height: '100vh' } }>
                <div className="main">

                    <div className="main-div">
                        <div className="left">
                            <div onClick={ () => pageChangeClick( 1 ) } className={ currentComponent == 1 ? "sec active" : "sec" }>Personal details</div>
                            <div onClick={ () => pageChangeClick( 2 ) } className={ currentComponent == 2 ? "sec active" : "sec" }>Your Address</div>
                            <div onClick={ () => pageChangeClick( 3 ) } className={ currentComponent == 3 ? "sec active" : "sec" }>Educational Details</div>
                            <div onClick={ () => pageChangeClick( 4 ) } className={ currentComponent == 4 ? "sec active" : "sec" }>Work Experience Details</div>
                            <div onClick={ () => pageChangeClick( 5 ) } className={ currentComponent == 5 ? "sec active" : "sec" }>Other Details</div>
                            <div onClick={ () => pageChangeClick( 6 ) } className={ currentComponent == 6 ? "sec active" : "sec" }>Resume / CV</div>
                        </div>
                        {
                            renderCurrentComponent()
                        }
                    </div>



                    <div className="button but">
                        { currentComponent !== 1 && (
                            < button className='prev' onClick={ prevClicked }>
                                Previous
                            </button>
                        ) }

                        { currentComponent !== 6 && (
                            <button className='next' onClick={ nextClicked }>
                                Next
                            </button>
                        ) }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default InternForm;

