import React from 'react';
import { useCommonContext } from '../../Contexts/CommonContext';
import PhoneInput from 'react-phone-number-input';
import { useState } from 'react';
import 'react-phone-number-input/style.css';


const TextInputV2 = ( { type, field, placeholder, label } ) =>
{
  const { state, dispatch } = useCommonContext();

  const handleChange = ( value ) =>
  {
    dispatch( { type: 'UPDATE_INPUT', payload: { field, value } } );
  };

  return (
    <div className="info-box">
      <label>{ label }</label>
      <PhoneInput
        // type={ type }
        value={ state[ field ] || '' }
        onChange={ ( e ) => handleChange( e ) }
        placeholder={ placeholder }

      />
    </div>
  );
};

export default TextInputV2;
