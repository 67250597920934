// AppStateContext.js
import React, { createContext, useState, useContext } from 'react';

const AppStateContext = createContext();

const AppStateProvider = ( { children } ) =>
{

  const [ dropdowns, setDropdowns ] = useState( {
    dropdown1: "Beginner", // Default option for dropdown1
  } );

  const [ appliedForPost, setAppliedForPost ] = useState( [] );
  const [ appliedForLanguage, setAppliedForLanguage ] = useState( [] );

  const setDropdownOption = ( dropdownName, option ) =>
  {
    setDropdowns( ( prevDropdowns ) => ( {
      ...prevDropdowns,
      [ dropdownName ]: option,
    } ) );
  };

  const toggleCheckboxPost = ( checkboxName ) =>
  {

    if ( appliedForPost.indexOf( checkboxName ) > -1 )
    {
      // remove checkboxName from appliedForPost
      setAppliedForPost( appliedForPost.filter( ( item ) => item !== checkboxName ) );
    } else
    {
      // add checkboxName to appliedForPost
      setAppliedForPost( [ ...appliedForPost, checkboxName ] );
    }
  };


  const toggleCheckboxLanguage = ( checkboxName ) =>
  {

    if ( appliedForLanguage.indexOf( checkboxName ) > -1 )
    {
      // remove checkboxName from appliedForLanguage
      setAppliedForLanguage( appliedForLanguage.filter( ( item ) => item !== checkboxName ) );
    } else
    {
      // add checkboxName to appliedForLanguage
      setAppliedForLanguage( [ ...appliedForLanguage, checkboxName ] );
    }
  };

  const [ selectedFile, setSelectedFile ] = useState( null );

  const setFile = ( file ) =>
  {
    setSelectedFile( file );
  };

  return (
    <AppStateContext.Provider
      value={ {
        dropdowns,
        setDropdownOption,
        appliedForPost,
        toggleCheckboxPost,
        appliedForLanguage,
        toggleCheckboxLanguage,
        selectedFile,
        setFile,
      } }
    >
      { children }
    </AppStateContext.Provider>
  );
};

const useAppStateContext = () =>
{
  const context = useContext( AppStateContext );
  if ( !context )
  {
    throw new Error( 'useAppStateContext must be used within an AppStateProvider' );
  }
  return context;
};

export { AppStateProvider, useAppStateContext };
