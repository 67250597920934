import React from 'react';
import { useNavigate } from 'react-router-dom';
import TextInput from './TextInput';
import { useAppStateContext } from '../Reducers/AppStateContext';

const Step4 = () =>
{

    const {
        dropdowns, setDropdownOption, appliedForPost, toggleCheckboxPost, appliedForLanguage, toggleCheckboxLanguage,
    } = useAppStateContext();


    const handleDropdownChange = ( dropdownName, e ) =>
    {
        setDropdownOption( dropdownName, e.target.value );
    };

    return (
        // <div className="main-div">
        //     <div className="left">
        //         <div className='sec'>Personal details</div>
        //         <div className='sec'>Your Address</div>
        //         <div className='sec'>Educational Details</div>
        //         <div className='sec active'>Work Experience Details</div>
        //         <div className='sec'>Other Details</div>
        //         <div className='sec'>Resume / CV</div>
        //     </div>
        <div className="right">
            <form className='main-scroll'>

                <div className="info-box">
                    <label>Preferred Coding Language: *</label>
                    <span>You can chose more then one field</span>
                    <div className='chk scroll mob'>

                        {/* <li>- HTML</li>
                        <li>- CSS</li>
                        <li>- JavaScript</li>
                        <li>- TypeScript</li>
                        <li>- XML</li>
                        <li>- PHP</li>
                        <li>- Python</li>
                        <li>- Java</li>
                        <li>- Kotlin</li>
                        <li>- Dart</li>
                        <li>- C</li>
                        <li>- C++</li>
                        <li>- C#</li> */}

                        <div className='opt'>
                            <input type="checkbox" value="HTML"
                                checked={ appliedForLanguage.indexOf( "HTML" ) > -1 }
                                onChange={ () => toggleCheckboxLanguage( 'HTML' ) } />
                            <div>HTML</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="CSS"
                                checked={ appliedForLanguage.indexOf( "CSS" ) > -1 }
                                onChange={ () => toggleCheckboxLanguage( 'CSS' ) } />
                            <div>CSS</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="JavaScript"
                                checked={ appliedForLanguage.indexOf( "JavaScript" ) > -1 }
                                onChange={ () => toggleCheckboxLanguage( 'JavaScript' ) } />
                            <div>JavaScript</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="TypeScript"
                                checked={ appliedForLanguage.indexOf( "TypeScript" ) > -1 }
                                onChange={ () => toggleCheckboxLanguage( 'TypeScript' ) } />
                            <div>TypeScript</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="XML"
                                checked={ appliedForLanguage.indexOf( "XML" ) > -1 }
                                onChange={ () => toggleCheckboxLanguage( 'XML' ) } />
                            <div>XML</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="PHP"
                                checked={ appliedForLanguage.indexOf( "PHP" ) > -1 }
                                onChange={ () => toggleCheckboxLanguage( 'PHP' ) } />
                            <div>PHP</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="Python"
                                checked={ appliedForLanguage.indexOf( "Python" ) > -1 }
                                onChange={ () => toggleCheckboxLanguage( 'Python' ) } />
                            <div>Python</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="Java"
                                checked={ appliedForLanguage.indexOf( "Java" ) > -1 }
                                onChange={ () => toggleCheckboxLanguage( 'Java' ) } />
                            <div>Java</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="Kotlin"
                                checked={ appliedForLanguage.indexOf( "Kotlin" ) > -1 }
                                onChange={ () => toggleCheckboxLanguage( 'Kotlin' ) } />
                            <div>Kotlin</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="Dart"
                                checked={ appliedForLanguage.indexOf( "Dart" ) > -1 }
                                onChange={ () => toggleCheckboxLanguage( 'Dart' ) } />
                            <div>Dart</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="C"
                                checked={ appliedForLanguage.indexOf( "C" ) > -1 }
                                onChange={ () => toggleCheckboxLanguage( 'C' ) } />
                            <div>C</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="C++"
                                checked={ appliedForLanguage.indexOf( "C++" ) > -1 }
                                onChange={ () => toggleCheckboxLanguage( 'C++' ) } />
                            <div>C++</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="C#"
                                checked={ appliedForLanguage.indexOf( "C#" ) > -1 }
                                onChange={ () => toggleCheckboxLanguage( 'C#' ) } />
                            <div>C#</div>
                        </div>



                    </div>
                </div>



                <div className="info-box">
                    <label for="lang">Strength of your choosen language: *</label>
                    <select value={ dropdowns.dropdown1 } onChange={ ( e ) => handleDropdownChange( 'dropdown1', e ) }>
                        <option value="Beginner">Beginner</option>
                        <option value="Intermediate">Intermediate</option>
                        <option value="Advanced">Advanced</option>
                    </select>
                </div>

                <div className="info-box">
                    <label>Applied for: *</label>
                    <span>You can chose more then one field</span>
                    <div className='chk scroll mob'>

                        {/* <li>- Flutter Developer</li>
                            <li>- WordPress Developer</li>
                            <li>- MERN Stack Developer</li>
                            <li>- Frontend Developer</li>
                            <li>- React.js Developer</li>
                            <li>- HTML,CSS & JS Developer</li>
                            <li>- Next.js Developer</li>
                            <li>- Backend Developer</li>
                            <li>- Node.js Developer</li>
                            <li>- PHP, MySQL Developer</li>
                            <li>- UI/UX Designer</li>
                            <li>- Graphics Designer</li>
                            <li>- SEO Analytics</li>
                            <li>- Blog Writing</li>
                            <li>- Blog Re-write</li>
                            <li>- Data Collection</li> */}

                        <div className='opt'>
                            <input type="checkbox" value="Flutter Developer"
                                checked={ appliedForPost.indexOf( "Flutter Developer" ) > -1 }
                                onChange={ () => toggleCheckboxPost( 'Flutter Developer' ) } />
                            <div>Flutter Developer</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="WordPress Developer"
                                checked={ appliedForPost.indexOf( "WordPress Developer" ) > -1 }
                                onChange={ () => toggleCheckboxPost( 'WordPress Developer' ) } />
                            <div>WordPress Developer</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="MERN Stack Developer"
                                checked={ appliedForPost.indexOf( "MERN Stack Developer" ) > -1 }
                                onChange={ () => toggleCheckboxPost( 'MERN Stack Developer' ) } />
                            <div>MERN Stack Developer</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="Frontend Developer"
                                checked={ appliedForPost.indexOf( "Frontend Developer" ) > -1 }
                                onChange={ () => toggleCheckboxPost( 'Frontend Developer' ) } />
                            <div>Frontend Developer</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="React.js Developer"
                                checked={ appliedForPost.indexOf( "React.js Developer" ) > -1 }
                                onChange={ () => toggleCheckboxPost( 'React.js Developer' ) } />
                            <div>React.js Developer</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="HTML,CSS & JS Developer"
                                checked={ appliedForPost.indexOf( "HTML,CSS & JS Developer" ) > -1 }
                                onChange={ () => toggleCheckboxPost( 'HTML,CSS & JS Developer' ) } />
                            <div>HTML,CSS & JS Developer</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="Next.js Developer"
                                checked={ appliedForPost.indexOf( "Next.js Developer" ) > -1 }
                                onChange={ () => toggleCheckboxPost( 'Next.js Developer' ) } />
                            <div>Next.js Developer</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="Backend Developer"
                                checked={ appliedForPost.indexOf( "Backend Developer" ) > -1 }
                                onChange={ () => toggleCheckboxPost( 'Backend Developer' ) } />
                            <div>Backend Developer</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="Node.js Developer"
                                checked={ appliedForPost.indexOf( "Node.js Developer" ) > -1 }
                                onChange={ () => toggleCheckboxPost( 'Node.js Developer' ) } />
                            <div>Node.js Developer</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="PHP, MySQL Developer"
                                checked={ appliedForPost.indexOf( "PHP, MySQL Developer" ) > -1 }
                                onChange={ () => toggleCheckboxPost( 'PHP, MySQL Developer' ) } />
                            <div>PHP, MySQL Developer</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="UI/UX Designer"
                                checked={ appliedForPost.indexOf( "UI/UX Designer" ) > -1 }
                                onChange={ () => toggleCheckboxPost( 'UI/UX Designer' ) } />
                            <div>UI/UX Designer</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="Graphics Designer"
                                checked={ appliedForPost.indexOf( "Graphics Designer" ) > -1 }
                                onChange={ () => toggleCheckboxPost( 'Graphics Designer' ) } />
                            <div>Graphics Designer</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="SEO Analytics"
                                checked={ appliedForPost.indexOf( "SEO Analytics" ) > -1 }
                                onChange={ () => toggleCheckboxPost( 'SEO Analytics' ) } />
                            <div>SEO Analytics</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="Blog Writing"
                                checked={ appliedForPost.indexOf( "Blog Writing" ) > -1 }
                                onChange={ () => toggleCheckboxPost( 'Blog Writing' ) } />
                            <div>Blog Writing</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="Blog Re-write"
                                checked={ appliedForPost.indexOf( "Blog Re-write" ) > -1 }
                                onChange={ () => toggleCheckboxPost( 'Blog Re-write' ) } />
                            <div>Blog Re-write</div>
                        </div>

                        <div className='opt'>
                            <input type="checkbox" value="Data Collection"
                                checked={ appliedForPost.indexOf( "Data Collection" ) > -1 }
                                onChange={ () => toggleCheckboxPost( 'Data Collection' ) } />
                            <div>Data Collection</div>
                        </div>











                    </div>
                </div>
            </form>
        </div>
        // </div>
    );
};

export default Step4;
